import React, { Component } from 'react';
import {BrowserRouter as Router,Route} from 'react-router-dom';
import './css/quoteForPrint.css';
import QuotationPrintVersion from "./QuotePrintVersion";
import QuotationWithLogo from "./QuotationWithLogo";
import NotFound from "./NotFound"
import Switch from "react-router-dom/es/Switch";

class App extends Component {
  render() {
    return (
        <Router>
            <div className="initial">
                <Switch>
                <Route
                    exact path={"/"}
                    component="test"
                />
                <Route
                    path={"/:clientId/:quotationId/:token"}
                    component={QuotationWithLogo}
                />

                <Route component={NotFound} />
                </Switch>
            </div>
        </Router>

    );
  }
}

export default App;
