import React from 'react';
import Logomarca from "./Logomarca";

export default class NotFound extends React.Component {

    render() {
        return (
            <div>
                <div className="failed">
                    <p>Cotação</p>
                    <br/>
                </div>
                <p className="quotationInfo">Visualize sua cotação a partir do link enviado por SMS.</p>
                <div className="loadingLogo">
                    <Logomarca/>
                </div>
            </div>
        )
    }
}