import Cliente from "./Cliente";
import Item from "./Item";
import Produto from "./Produto";
import {timeConverter} from './utils.js'
import React from "react";

export class Cotacao{
    constructor(cotacao){
        if(cotacao){
            if("desconto" in cotacao){
                this.desconto=cotacao.desconto
            }
            if("valorTransporte" in cotacao){
                this.valorTransporte = cotacao.valorTransporte
            }
            if("clienteResponsavelPeloFrete" in cotacao){
                this.clienteResponsavelPeloFrete=cotacao.clienteResponsavelPeloFrete
            }
            if("itens" in cotacao){
                this.itens=cotacao.itens;
            }
            if("expiresIn" in cotacao){
                this.expiresIn=cotacao.expiresIn
            }
            if("timeCreated" in cotacao){
                this.timeCreated=cotacao.timeCreated
            }
            if("note" in cotacao){
                this.note=cotacao.note
            }
            if("paymentCondition" in cotacao){
                this.paymentCondition=cotacao.paymentCondition
            }
        }

    }

    static buildQuote(cotacao,listaDeClientes,estoque){
        let cliente=null;

        try{
            cliente=new Cliente(cotacao.cliente)
        }catch(err){console.log(err)}
        if(!cliente){
            cliente=new Cliente()
        }
        let itens=null
        let newCotacao=null
        try{
            newCotacao=new Cotacao(cliente,
                cotacao.getTransportador(),cotacao.getValorTransporte(),
                cotacao.getDesconto(),cotacao.getObservacoes(),cotacao.getEmissao(),
                cotacao.getValidade(),cotacao.getContaBancaria,
                cotacao.isClientPayingFreight(),cotacao.getStatus(),cotacao.getQuotationId(),cotacao.getItens());
        }catch(err){
            newCotacao=new Cotacao(cliente)
        }
        try{
            itens=cotacao.getItens()
        }catch(err){
            itens=[]
        }


        for(let i=0;i<itens.length;i++){
            let productId=itens[i].produto.productId
            let price = itens[i].produto.price
            //copies product from stock, ensuring price is the
            //same as originally set when sending the quote
            let produto= Produto.buildProduct(productId,estoque,price)
            let quantity=cotacao.itens[i].quantity
            let item=new Item(produto,quantity)
            newCotacao.addItem(item)
        }
        if(newCotacao){
            return newCotacao
        }
        else{
            return (new Cotacao())
        }
    }

    parseQuote(cotacao){
        try{
            let itens=[]
            for(let i=0;i<cotacao.itens.length;i++){
                let produto=new Produto(cotacao.itens[i].produto)
                let quantity=cotacao.itens[i].quantity
                let item = new Item(produto,quantity)
                itens.push(item)
            }
            this.itens=itens
        }catch(e){
            this.itens=[]
        }
        try{
            let cliente=new Cliente(cotacao.cliente)
            this.cliente=cliente
        }catch(e){
            this.cliente=new Cliente()
        }
        try{this.transportador=cotacao.transportador}catch(e){
            this.transportador=null
        }
        try{this.valorTransporte=cotacao.valorTransporte}catch(e){
            this.valorTransporte=null
        }
        try{this.desconto=cotacao.desconto}catch(e){this.desconto=null};
        try{this.observacoes=cotacao.observacoes}catch(e){this.observacoes=null};
        try{this.emissao=cotacao.emissao}catch(e){this.emissao=null};
        try{this.validade=cotacao.validade}catch(e){this.validade=null};
        try{this.contaBancaria=cotacao.contaBancaria}catch(e){this.contaBancaria=null};
        try{this.clienteResponsavelPeloFrete=cotacao.clienteResponsavelPeloFrete}catch(e){this.clienteResponsavelPeloFrete=true};
        try{this.situacao=cotacao.situacao}catch(e){this.situacao=null}
        try{this.quotationId=cotacao.quotationId}catch(e){this.quotationId=null}

    }
    getCondition(){
        if(this.paymentCondition){
            if(this.paymentCondition!==null){
                if(this.paymentCondition!==""){
                    return this.paymentCondition
                }
            }
        }
        return (
            "Cotação válida até "+timeConverter(this.expiresIn))
    }
    copy(cotacao){
        this.itens=cotacao.getItens();
        this.cliente=cotacao.getCliente();
        this.transportador=cotacao.getTransportador();
        this.valorTransporte=cotacao.getValorTransporte();
        this.desconto=cotacao.getDesconto();
        this.observacoes=cotacao.getObservacoes();
        this.emissao=cotacao.getEmissao();
        this.validade=cotacao.getValidade();
        this.contaBancaria=cotacao.getContaBancaria();
        this.clienteResponsavelPeloFrete=cotacao.isClientPayingFreight();

    }
    //setters
    setQuotationId(quotationId){
        this.quotationId=quotationId
    }
    setItens(itens){
        this.itens=itens
    }
    addItem(item){
        //check if duplicate
        for(let i=0;i<this.itens.length;i++){
            if(item.isDuplicate(this.itens[i])){
                return "Error. Item is duplicate"
            }
        }
        let itens=this.itens;
        itens.push(item);
        this.itens=itens;
    }
    setClient(cliente){
        this.cliente=cliente
    }
    setTransportador(transportador){
        this.transportador=transportador;
    }
    removeItem(productId){
        let itens=[];
        for(let i=0;i<this.itens.length;i++){
            if(!(this.itens[i].getProduto().getProductId()===productId)){
                itens.push(this.itens[i])
            }
        }
        this.itens=itens;
    }
    setValorDoTransporte(custo){
        this.valorTransporte=custo
    }
    setResponsibleForFreight(responsible){
        if(responsible==="cliente"){
            this.clienteResponsavelPeloFrete=true
        }
        else{
            this.clienteResponsavelPeloFrete=false
        }
    }
    setDesconto(desconto){
        this.desconto=desconto
    }

    getWeight(){
        let totalWeight=0;
        try{
            this.itens.forEach(item=>{
                totalWeight=(item.getWeight()*1+totalWeight)*1
            })
            return totalWeight
        }catch(err){
            return 0
        }
    }

    //getters
    getSubtotalDosProdutos(){
        let subtotal=0;
        for(let i=0;i<this.itens.length;i++){
            subtotal=(subtotal+(this.itens[i].getSubtotal()*1))
        }
        return subtotal.toFixed(2)
    }
    getTotal(){
        try{
            let total=this.getSubtotalDosProdutos();
            if(this.clienteResponsavelPeloFrete){
                total=(total*1+this.valorTransporte*1)*1;
            }
            if(this.desconto){
                total=(total*1-this.desconto*1)*1;
            }

            return (total*1).toFixed(2);
        }catch(err){
            return 0
        }

    }
    getQuotationId(){
        if("quotationId" in this){
            return this.quotationId
        }
        return null
    }
    getItens(){
        if("itens" in this){return this.itens}
        else{return []}
    }
    getCliente(){
        if(this.cliente){
        return this.cliente}
        return null
    }
    getClientName(){
        if("cliente" in this){
            if("Nome" in this.cliente){
                return this.cliente.Nome
            }
        }
            return ""

    }
    getTransportador(){
        if("transportador" in this){
            return this.transportador
        }
        return null
    }
    getTransportadorName(){
        if("transportador" in this){
            return this.transportador.getNome()
        }
        return null
    }
    getValorTransporte(){
        if("valorTransporte" in this){
            return this.valorTransporte
        }
        return null
    }
    getDesconto(){
        if("desconto" in this){
            return this.desconto
        }
        return null
    }
    getObservacoes(){
        if("observacoes" in this){
            return this.observacoes
        }
        return ""
    }
    getEmissao(){
        if("emissao" in this){
            return this.emissao
        }
        return null
    }
    getValidade(){
        if("validade" in this){
            return this.validade
        }
        return null
    }
    getExpiryDate(){
        if("emissao" in this){
            if("validade in this"){
                return this.emissao+this.validade
            }
        }
        return null;
    }
    getContaBancaria(){
        if("contaBancaria" in this){
            return this.contaBancaria
        }
        return null
    }
    isClientPayingFreight(){
        if("clienteResponsavelPeloFrete" in this){
            return this.clienteResponsavelPeloFrete
        }
        return null
    }

    getSituacao(){
        if("situacao" in this){
            return this.situacao
        }
        return null
    }



}