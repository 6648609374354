//to create a Product object as per database data
import {Component} from 'react'
class Produto extends Component {

    constructor(produto){
        super();
        this.productId="";
        this.description="";
        this.name="";
        this.availability=0;
        this.unity="";
        this.currency="R$";
        this.gramsPerSquareCentimeter="";
        this.kgPerMeter="";
        this.kgPerUnit="";
        this.partNumber="";
        this.price=0;
        if("name" in produto){this.name=produto.name}
        if("productId" in produto){this.productId=produto.productId}
        if("description" in produto){this.description=produto.description}
        if("availability" in produto){this.availability=produto.availability}
        if("unity" in produto){this.unity=produto.unity}
        if("currency" in produto){this.currency=produto.currency}
        if("gramsPerSquareCentimeter" in produto){this.produto=produto.gramsPerSquareCentimeter}
        if("kgPerMeter" in produto){this.kgPerMeter=produto.kgPerMeter}
        if("kgPerUnit" in produto){this.kgPerUnit=produto.kgPerUnit}
        if("partNumber" in produto){this.partNumber=produto.partNumber}
        if("price" in produto){this.price=produto.price}
    }

    //setters
    setProductId(productId){
        this.productId=productId;
    }
    setPrice(newPrice){
        this.price=newPrice
    }
    setDescription(description){
        this.description=description;
    }
    setAvailability(availability){
        this.availability=availability;
    }

    //getters
    getPrice(){
        return this.price
    }
    getDescription(){
        if(this.description){
            return this.description
        }
        return ""
    }

    getNameAndDescription(){
        return (this.getName() + " - "+ this.getDescription())
    }

    getName(){
        if(this.name){
            return this.name
        }
        return ""
    }

    getAvailability(){
        return this.availability;
}

    getKgPerUnit(){
        return (this.kgPerUnit*1).toFixed(2)
    }

    getProductId(){
        return this.productId;
    }

    getUnity(){return this.unity}

    isTheSame(produto){
        if(this.productId===produto.productId){
            return true
        }
        return false;
    }

    //useful when retrieving items from a quote
    static buildProduct(productId,estoque,price){
        let produto=estoque.getProduct(productId)
        produto.setPrice(price)
        return produto

    }
}

export default Produto;