//to create a Product object as per database data
import {Component} from 'react'
import {capitalizeString} from "./utils";

class Cliente extends Component {

    constructor(cliente){
        super();
        try{this.clientId=cliente.clientId}catch(err){console.log(err)};
        try{this.suburb=cliente.suburb}catch(err){console.log(err)};
        try{this.zipCode=cliente.zipCode}catch(err){console.log(err)};
        try{this.CPF_CNPJ=cliente.id}catch(err){console.log(err)};
        try{this.Celular=cliente.Celular}catch(err){console.log(err)};
        try{this.Consignado=cliente.Consignado}catch(err){console.log(err)};
        try{this.IE=cliente.IE}catch(err){console.log(err)};
        try{this.Email=cliente.Email}catch(err){console.log(err)};
        try{this.Email2=cliente.Email2}catch(err){console.log(err)};
        try{this.address=cliente.address}catch(err){console.log(err)};
        try{this.state=cliente.state}catch(err){console.log(err)};
        try{this.city=cliente.city}catch(err){console.log(err)};
        try{this.name=cliente.name}catch(err){console.log(err)};
        try{this.bankPreference=cliente.bankPreference}catch(err){console.log(err)};
        try{this.Telefone=cliente.Telefone}catch(err){console.log(err)};
        try{this.TelefoneClaro=cliente.TelefoneClaro}catch(err){console.log(err)};
        try{this.TelefoneOi=cliente.TelefoneOi}catch(err){console.log(err)};
        try{this.TelefoneTim=cliente.TelefoneTim}catch(err){console.log(err)};
        try{this.TelefoneVivo=cliente.TelefoneVivo}catch(err){console.log(err)};
        try{this.Balance=cliente.Balance}catch(err){console.log(err)};
        try{this.Transactions=cliente.Transactions}catch(err){console.log(err)};
        try{this.DadosAdicionais=cliente.DadosAdicionais}catch(err){console.log(err)};



    }

    //setters


    //getters
    getEndereco(){
        if("Endereco" in this){
            if(this.Endereco===""){
                return ""
            }
            else{
                return this.Endereco
            }
        }

        return ""
    }
    getBairro(){
        if(this.Bairro===""){
            return ""
        }
        return this.Bairro
    }
    getBalance(){
        if("Balance" in this){
            return this.Balance
        }
        return 0
    }
    getMunicipio(){
        if(this.Municipio===""){
            return ""
        }
        return this.Municipio
    }
    getUF(){
        if(this.Estado===""){
            return ""
        }
        return this.Estado
    }
    getTelefone(){
        if(this.Telefone===""){
            return ""
        }
        return this.Telefone
    }
    getDadosAdicionais(){
        if(this.DadosAdicionais===""){
            return ""
        }
        return this.DadosAdicionais
    }
    getCelular(){
        if(this.Celular===""){
            return ""
        }
        return this.Celular
    }
    getIE(){
        if(this.IE===""){
            return ""
        }
        return this.IE
    }
    getCep(){
        if(this.Cep===""){
            return ""
        }
        return this.CEP
    }
    getEmail(){
        if(this.Email===""){
            return ""
        }
        return this.Email
    }
   getName(){
        if(this.name===""){
            return ""
        }

        return capitalizeString(this.name)
    }
    getClientId(){
        return this.clientId
    }

    getCpfCnpj(){
        if("CPF_CNPJ" in this){
            if(this.CPF_CNPJ){
                return ("CNPJ/CPF: "+this.CPF_CNPJ)
            }
        }
        else return ""
    }

}

export default Cliente;