import React from 'react';

export default class QuotationProductDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state={
        }
    }

    showTransportRows(){
        if(this.props.cotacao.isClientPayingFreight()){
            return(
                <tr>
                    <td colSpan="5" className="blankRow">
                    </td>
                    <td className="subtotalLabel">Transporte: </td>
                    <td className="subtotalValue" colSpan="2">
                        <div id="moneyValue">
                        R$ {this.props.cotacao.getValorTransporte()}
                        </div>
                    </td>
                </tr>
            )}
            else{
                return (
                    <tr>
                        <td colSpan="5" className="blankRow">
                        </td>
                        <td className="subtotalValue" colSpan="3">
                            <div id="moneyValue">
                                FRETE INCLUSO
                            </div>
                        </td>
                    </tr>
                )
            }
        }


    showDescontoRows(){
        if(this.props.cotacao.getDesconto()>0){
            return(
                <tr>
                    <td colSpan="5" className="blankRow">
                    </td>
                    <td className="subtotalLabel">Desconto: </td>
                    <td className="subtotalValue" colSpan="2">
                        <div id="moneyValue">
                        R$ {this.props.cotacao.getDesconto()}
                        </div>
                    </td>
                </tr>
            )}
        else{
                return ""
            }

    }

    //remember to sync localStorage with server responses

    render() {
        return (
                <table id="items">
                    <tbody>
                    <tr>
                        <th>ITEM</th>
                        <th>Descrição</th>
                        <th>UN</th>
                        <th>Peso/un (kg)</th>
                        <th>R$/un</th>
                        <th>Qtd</th>
                        <th>Peso (kg)</th>
                        <th>TOTAL (R$)</th>

                    </tr>
                    {this.props.cotacao.getItens().map(function(item, i){
                        return(
                            <tr key={i}>
                                <td>{i+1}</td>
                                <td>{item.getProduto().getNameAndDescription()}</td>
                                <td>{item.getProduto().getUnity()}</td>
                                <td>{item.getProduto().getKgPerUnit()}</td>
                                <td>{item.getProduto().getPrice()}</td>
                                <td>{item.getQuantity()}</td>
                                <td>{item.getWeight()}</td>
                                <td>{item.getSubtotal().toFixed(2)}</td>
                            </tr>
                        )
                    },this)}
                    <tr>
                        <td className="blankRow" colSpan="5">
                        </td>
                        <td className="subtotalLabel">Peso total: </td>
                        <td className="subtotalValue" colSpan="2">
                            <div id="moneyValue">
                                {this.props.cotacao.getWeight()} kg
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="blankRow" colSpan="5">
                        </td>
                        <td className="subtotalLabel">Subtotal: </td>
                        <td className="subtotalValue" colSpan="2">
                            <div id="moneyValue">
                            R$ {this.props.cotacao.getSubtotalDosProdutos()}
                            </div>
                        </td>
                    </tr>
                    {this.showTransportRows()}
                    {this.showDescontoRows()}
                    <tr>
                        <td className="blankRow" colSpan="5">
                        </td>
                        <td className="totalLabel">Total: </td>
                        <td className="totalValue" colSpan="2">
                            <div id="moneyValue">
                                R$ {this.props.cotacao.getTotal()}
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
        )
    }
}