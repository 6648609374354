
//to create a Product object as per database data
export default class Item{

    constructor(produto, quantity){
        this.produto=produto;
        this.quantity=quantity;
    }

    //setters
    setQuantity(newQuantity){
        this.quantity=newQuantity;
    }
    //getters

    getWeight(){
        return (((this.quantity*1)*(this.produto.getKgPerUnit()*1)).toFixed(2)).toString()
    }

    getQuantity(){return (this.quantity*1).toFixed(2)}
    getSubtotal(){
        return (this.quantity*1)*(this.produto.getPrice()*1)
    }
    getProduto(){
        return this.produto
    }

    getProductId(){
        return this.produto.getProductId()
    }

    containsProduct(produto){
        if(this.produto.getProductId()===produto.getProductId()){
            return true;
        }
        return false;
    }

    isDuplicate(item){
        if(this.produto.getProductId()===item.getProduto().getProductId()){
            return true;
        }
        return false;
    }


}
