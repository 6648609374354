import React from 'react';
import {Cotacao} from "./model/Cotacao";
import Produto from "./model/Produto";
import Item from "./model/Item";
import Cliente from "./model/Cliente";
import QuotationProductDetails from "./QuotationProductDetails";
import Logomarca from "./Logomarca";
import Loading from "./Loading"
import './css/papelTimbrado.css'
import stack from './stack.json'
import {timeConverter} from './model/utils.js'
const API_URL= stack.ServiceEndpoint;
export default class QuotationWithLogo extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            quotationId:props.match.params.quotationId,
            token:props.match.params.token,
            clientId:props.match.params.clientId,
            cotacao:new Cotacao(),
            details: '',
            cliente:new Cliente(),
            clientBalance:0,
            date:'',
            expiresIn:'',
            bank:null,
            banksList:null,
            status:"updating"
        }
    }

    componentWillMount(){
        this.getQuote()
    }

    showNotes(){
        if(this.state.cotacao){
            if(this.state.cotacao.note){
                return(
                    <div id="note">
                        {this.state.cotacao.note}
                    </div>
                )
            }
        }
    }

    getClientBalance(clientId){
        fetch (API_URL+"/clients/"+clientId, {
            method: "GET",
            mode:"cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(response => {
                if("Balance" in response){
                    this.setState({
                        clientBalance:response.Balance
                    })
                }
            })
            .catch(error => {
                console.error('Error:', error)
            });
    }

    getQuote(){
        fetch (API_URL+"/"+this.state.clientId+"/"+this.state.quotationId+"/"+this.state.token, {
            method: "GET",
            mode:"cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(response => {
                if(response.statusCode){
                    if(response.statusCode===200){
                        if("quotation" in response.body){
                            let itens=[]
                            for(let i=0;i<response.body.quotation.itens.length;i++){
                                let produto=new Produto(response.body.quotation.itens[i].produto);
                                let quantity=response.body.quotation.itens[i].quantity
                                let item=new Item(produto,quantity)
                                itens.push(item)
                            }
                            let cotacao=new Cotacao(response.body.quotation)
                            cotacao.setItens(itens)
                            let cliente=new Cliente(response.body.quotation.cliente)
                            //this.getClientBalance(response.body.quotation.cliente.clientId)
                            let bank=null
                            if("bank" in response.body.quotation){
                                bank=response.body.quotation.bank
                            }
                            this.setState(
                                {
                                    cotacao:cotacao,
                                    details: <QuotationProductDetails
                                        cotacao={cotacao}/>,
                                    cliente:cliente,
                                    date:timeConverter(cotacao.timeCreated),
                                    expiresIn:timeConverter(cotacao.expiresIn),
                                    bank:bank,
                                    status:"populated"
                                }
                            )
                        }
                        else{
                            this.setState(
                                {status:"failed"}
                            )
                        }
                    }
                    else{
                        this.setState(
                            {status:"failed"}
                        )
                    }
                }

            })
            .catch(error => {
                console.error('Error:', error)
                this.setState(
                    {status:"failed"}
                )
            });
    }

    showBank(){
        if(this.state.bank){
            let id=null;
            if(this.state.bank.type==='business'){
                id="CNPJ: "+this.state.bank.id
            }
            else{
                id="CPF: "+this.state.bank.id
            }
            return(
                <div id="bankData">
                    <div>Conta para depósito:</div>
                    <div id="accountHolder">{this.state.bank.accountHolder}</div>
                    <div id="accountHolderId">{id}</div>
                    <div id="bankName">Banco: {this.state.bank.bankName}</div>
                    <div id="branch">Agência: {this.state.bank.branch}</div>
                    <div id="accountNumber">Número: {this.state.bank.accountNumber}</div>
                </div>
            )
        }
    }

    getAddress=()=>{
        if(this.state.cliente.address){
            return <table><tr><td>{this.state.cliente.address}</td></tr></table>
        }
        else{return ""}
    }

    getSuburb=()=>{
        if(this.state.cliente.suburb){
            return <table ><tr><td>{this.state.cliente.suburb}</td></tr></table>
        }
        else{return ""}
    }
    getCityAndState=()=>{
        if(this.state.cliente.city){
            if(this.state.cliente.state){
                return <table ><tr><td>{this.state.cliente.city}, {this.state.cliente.state} {this.getZipCode()}</td></tr></table>
            }
            return  <table ><tr><td>{this.state.cliente.city}</td></tr></table>
        }
        return ""
    };
    getZipCode=()=>{
        if(this.state.cliente.zipCode){
            return "CEP: "+this.state.cliente.zipCode
        }
        else{return ""}
    }
    showContactDetails(){
        if(this.state.cliente){
            let cliente=this.state.cliente;

            return(
                <div>
                    {this.getAddress()}
                    {this.getSuburb()}
                    {this.getCityAndState()}
                </div>
            )
        }
    }

    showAerotubosContactDetails(){
        return(
            <div className="papelTimbradoEndereco">
                <table><tr><td>RUA JÚLIO VAZ DE CARVALHO, 800</td></tr></table>
                <table><tr><td>V. N. SRA DE FÁTIMA - BOTUCATU, SP, 18608-395</td></tr></table>
                <table><tr><td>CNPJ: 01.976.367/0001-08 | Ins. Est. 224.087.604-110</td></tr></table>
                <table><tr><td><a href="https://www.aerotubos.com" target="_blank">https://www.aerotubos.com</a> | <a href="mailto:vendas@aerotubos.com">vendas@aerotubos.com</a> | (14) 3813-6040</td></tr></table>
            </div>
        )
    }

    showBalance(){
        if(this.state.clientBalance>0){
            return(
                <div className="finalBalance">
                    <div>
                        Balanço atual: R$ {this.state.clientBalance}
                    </div>
                    <div>
                        Valor atualizado com este pedido:
                        <span className="valorFinal">
                        R$ {(this.state.cotacao.getTotal()*1+this.state.clientBalance*1)*1}
                        </span>
                    </div>
                </div>

            )
        }
        else{
            return <div className="finalBalance">
                <div>
                    Valor a pagar: R$
                    <span>
                        {(this.state.cotacao.getTotal()*1+this.state.clientBalance*1)*1}
                        </span>
                </div>
            </div>
        }
    }

    //remember to sync localStorage with server responses

    render() {
        if(this.state.status==="populated"){
            let element=(
                <div id="page-wrap">
                    <div id="header">COTAÇÃO</div>
                    <div id="logoContainerOutter">
                        <Logomarca/>
                    </div>
                    {this.showAerotubosContactDetails()}
                    <div id="papel-timbrado-customer-data">
                        <p>{this.state.cliente.getName()}</p>
                        <p>{this.state.cliente.getCpfCnpj()}</p>
                        {this.showContactDetails()}
                    </div>


                    <div id="customer">

                        <table id="meta">
                            <tbody>
                            <tr>
                                <td className="meta-head">Cotação</td>
                                <td><div>{this.state.quotationId}</div></td>
                            </tr>
                            <tr>
                                <td className="meta-head">Emitida em</td>
                                <td><div id="date">{this.state.date}</div></td>
                            </tr>
                            <tr>
                                <td className="meta-head">Valor</td>
                                <td>
                                    <div className="due">R$ {this.state.cotacao.getTotal()}</div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    {this.state.details}
                    {this.showBank()}
                    <div id="terms">
                        <h5>Condições de pagamento</h5>
                        <div>{this.state.cotacao.getCondition()}</div>
                    </div>
                    {this.showNotes()}
                </div>
            )
            return (
                element
            )
        }
        if(this.state.status==="failed"){
            return(
                <div>
                    <div className="failed">
                        <p>impossível carregar cotação com os dados informados. Se o erro persistir, favor contactar-nos informando o número da cotação.</p>
                        <br/>
                        <p>leonardo@aerotubos.com</p>

                    </div>
                    <div className="loadingLogo">
                        <Logomarca/>
                    </div>
                </div>
            )
        }

        return(
            <div>
                <div className="loadingContainer">
                    <Loading/>

                </div>
                <p className="loadingInfo">
                    Carregando cotação
                </p>
                <div className="loadingLogo">
                    <Logomarca/>
                </div>
            </div>
        )


    }
}