import React from 'react';
import './css/logomarca.css'


export default class Logomarca extends React.Component {
    constructor(props) {
        super(props);
        this.state={

        }
    }


    render() {
        return(
            <div className="logoContainer">
                <div className="logoSquare">
                    <div className="aerotubosBackground2">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <div className="aerotubosLogo">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div>
                        <span className="firstBar"></span>
                        <span className="secondBar"></span>
                    </div>
                    <div></div>
                    <div>
                        <span className="thirdBar"></span>
                        <span className="fourthBar"></span>
                    </div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div id="logoLetters">AEROTUBOS</div>
            </div>
        )
    }

}